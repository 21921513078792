.k-pdf-export .hideDuringPrint {
  display: none;
}

.darkClass {
  -webkit-filter: saturate(0%);
  filter: saturate(0%);
  opacity: 0.5;
  pointer-events: none;
}


.user-select-none {
  user-select: none;
}

.cursor-pointer {
  cursor: pointer !important;
}

b,
strong {
  font-weight: bold; // Add the correct font weight in Chrome, Edge, and Safari
}

.cursor-text {
  cursor: text !important;
}

.cursor-default {
  cursor: default !important;
}

.table-layout-fixed {
  table-layout: fixed;
}

//favorite icons in sidebar
.nav-link .favorite-icon {
  display: none;
  margin: 0 !important;
  line-height: 0 !important;
}

.favorites .nav-link .favorite-icon.fas,
.nav-link:hover .favorite-icon {
  display: inline;
}

//hide farvorite icon when mimimezed
.g-sidenav-hidden:not(.g-sidenav-show) .sidenav .nav-item .collapse .favorite-icon {
  display: none !important;
}

//hide arrows when minimized
.g-sidenav-hidden:not(.g-sidenav-show) .sidenav .navbar-nav .nav-item .nav-link:after {
  content: "";
}

.round-borders {
  border-radius: 0.375rem;
}

.card-profile-image-custom {
  position: relative;
  transform: translate(-50%, -50%);
  left: 50%;
}

.card-profile-image-custom img {
  border: 3px solid #fff;
  transform: scale(1);

  position: absolute;
  transition: all 0.15s ease;
}

.card-profile-image-custom img:hover {
  transform: scale(1.03);
}

//email reader modal
.email-modal .modal-dialog {
  max-width: 50%;
}

.email-modal pre {
  white-space: pre-wrap;
}

.email-modal .modal-body {
  max-height: 500px;
  overflow-y: scroll;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiSlider-thumb.Mui-disabled {
  width: 15px !important;
  height: 15px !important;
  margin-top: -5px !important;
  margin-left: -7px !important;
}

.rotating {
  animation: rotating 2s linear infinite;
}

//tables scrollbar fix
.row.react-bootstrap-table-pagination {
  margin-left: 0;
  margin-right: 0;
}

.max-two-lines {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.pointer-events-none {
  pointer-events: none !important;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.div-striped {
  >div:nth-of-type(#{$table-striped-order}) {
    background-color: $table-accent-bg;
  }
}

.ory-cell-droppable-container,
.ory-cell-draggable-container,
:not(.ory-row).ory-cell-leaf {
  width: 100%;
  height: 100%;
}

.scrollbar-fade::after {
  content: "";
  position: absolute;
  pointer-events: none;
  width: 100%;
  top: 0;
  bottom: 0;
  background: linear-gradient(to bottom,
      rgba(247, 250, 252, 0.8) 0%,
      rgba(247, 250, 252, 0) 10%,
      rgba(247, 250, 252, 0) 90%,
      rgba(247, 250, 252, 0.8) 100%);
}

.ory-cell-inner p {
  line-height: unset; //default line spacing is 1.7 from the theme
  font-size: unset; //default font size is strange from the theme
}

//spacing in the card that lists the qualifications
#qualifications_card .badge {
  white-space: initial;
}

.react-parallax-background-children {
  width: 100%;
}

.react-parallax-background-children img {
  width: 100%;
}

body {
  background-color: white;
}

p {
  margin: 0;
}

.pac-container:after { //remove power by google logo
  background-image: none !important;
  height: 0px;
}