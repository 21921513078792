.sidebar {
  min-width: 320px;
  max-width: 320px;
  color: #fff;
  margin-right: -320px;
  z-index: 3000;
  overflow: hidden;
}

.sidebar.is-open {
  margin-right: 0;
}
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  height: 100vh;
  overflow: auto;
}

@media only screen and (max-width: 500px) {
  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
}

div[id^="font-picker"].expanded {
  height: 200px;
}

div[id^="font-picker"] {
  overflow: hidden;
  border-radius: 5px;
  width: 100% !important;
}

div[id^="font-picker"] .dropdown-icon::before {
  border-top: 6px solid #fff !important;
}

div[id^="font-picker"] button {
  background-color: #000 !important;
}
